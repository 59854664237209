<template>
  <section
    class="w-screen bg-verde grid grid-cols-1 place-content-center font-serif"
    :class="separadores ? 'py-40 border-y-8 border-verde' : 'py-36'"
    id="contacto"
  >
    <div class="container mx-auto text-dorado">
      <h1 class="text-xl pb-6 px-6">
        Gracias por su interés en FIRMA DE ABOGADOS ÉLITE, para poder apoyarlo,
        por favor contactenos:
      </h1>
      <div
        class="
          m-4
          grid grid-cols-1
          md:grid-cols-2
          gap-8
          place-content-center
          text-white
        "
      >
        <a href="tel:4441808193" class="text-xl p-6 no-underline"
          ><i class="fa-solid fa-phone"></i
          ><strong> +52 444 1 80 81 93</strong></a
        >
        <a
          href="mailto:contacto@elite-abogados.com.mx"
          class="text-xl p-6 no-underline"
          ><i class="fa-solid fa-envelope"></i
          ><strong> contacto@elite-abogados.com.mx</strong></a
        >
      </div>
      <h1 class="text-xl pt-6 px-6">
        Nosotros lo atenderemos con gusto en nuestro horario de oficina de lunes
        a viernes de 9:00 a 14:00 y de 16:30 a 20:30.
      </h1>
    </div>
  </section>
</template>
<script>
export default {
  name: "BotonAgendaCita",
  props: {
    lang: {
      type: String,
      default: "es",
    },
    separadores: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
