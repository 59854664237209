<template>
  <div>
    <Menu :lang="lang" />
    <section class="w-screen min-h-screen md:h-auto bg-white font-serif">
      <div class="w-screen h-20"></div>
      <div
        class="
          container
          mx-auto
          text-center
          grid grid-cols-1
          place-content-center
        "
      >
        <div class="col-span-1 p-8 md:px-0">
          <h1 class="py-4 text-2xl capitalize">Firma de abogados ÉLITE</h1>
          <p v-if="lang == 'es'">
            Despacho jurídico especializado en las principales áreas que
            requiere el sector empresarial.
          </p>
          <p v-if="lang == 'en'">
            Law firm specialized in key areas required by the corporate sector.
          </p>
        </div>
        <p>&nbsp;</p>
        <div
          class="
            grid grid-cols-1
            md:grid-cols-2
            gap-4
            place-content-center
            p-4
            md:px-0
          "
        >
          <div class="grid grid-cols-1 place-content-start">
            <img src="galeria/galeria 3.jpg" />
            <div class="text-center md:text-left text-verde py-8 capitalize">
              <h1 class="text-3xl">Firma de abogados ÉLITE</h1>
              <h2 class="text-2xl">Servicio legal integral</h2>
            </div>
          </div>
          <div>
            <div
              v-for="(elem, indice) in elems[lang]"
              :key="indice"
              class="md:col-span-2 p-6 grid grid-cols-1"
            >
              <div class="grid grid-cols-1 md:grid-cols-7 gap-6">
                <img
                  :src="'SVG/' + elem.i + '.svg'"
                  class="w-16 m-4 md:col-span-2"
                />
                <div class="text-left md:col-span-5">
                  <h1 class="text-2xl text-dorado">{{ elem.t }}</h1>
                  <p class="tracking-wide" v-html="elem.c"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <BotonAgendaCita2 :lang="lang" :separadores="true" />
    <Footer :lang="lang" />
  </div>
</template>
<script>
//import Api from "@/components/Api";
import Menu from "@/components/Menu";
import Footer from "@/components/Footer";
import BotonAgendaCita2 from "./BotonAgendaCita2.vue";
export default {
  name: "Nosotros",
  components: {
    Menu,
    Footer,
    BotonAgendaCita2,
  },
  data() {
    return {
      lang: localStorage.getItem("lang") || "es",
      elems: {
        es: [
          {
            t: "Política ",
            c: "Estamos comprometidos con la satisfacción de todas y cada una de las necesidades jurídicas de nuestros clientes, brindando un servicio de defensa y asesoría personalizado. ",
            i: "politicas",
          },
          {
            t: "Objetivo",
            c: "Brindar al cliente asesoría legal integral a través de los mejores especialistas en cada una de las áreas del despacho, otorgando prioridad a los ejes rectores de nuestro trabajo, que consisten en una actitud de PROFESIONALISMO Y COMPROMISO.",
            i: "objetivo",
          },
          {
            t: "Visión ",
            c: "Posicionar al despacho como aquel que cuenta con el mejor servicio al cliente, distinguiéndose por la capacidad de brindar asesoría legal integral y por un excelente dominio de cada una de las áreas que se ofrecen.",
            i: "vision",
          },
          {
            t: "Misión",
            c: "Brindar un servicio profesional sin dejar de lado el trato cercano y personalizado a nuestros clientes, ofreciendo propuestas prácticas que permitan una buena resolución de cualquier conflicto legal.",
            i: "mision",
          },
          {
            t: "Valores",
            c: `<p>En Firma de Abogados Élite tenemos como guías el profesionalismo, la integridad, la lealtad, el compromiso y la responsabilidad.</p>
<br />
<ul class="text-left">
<li><b>Profesionalismo</b>: Trabajamos con claridad y transparencia siguiendo los más altos estándares.</li>
<li><b>Integridad</b>: Prevalece la honestidad y la buena fe en la resolución de los asuntos de nuestros clientes. </li>
<li><b>Lealtad</b>: Otorgamos seguridad y tranquilidad a nuestros clientes mediante una resolución puntual a sus asuntos.</li>
<li><b>Compromiso</b>: Priorizamos los intereses de nuestros clientes y la adecuada resolución de conflictos.</li>
<li><b>Responsabilidad</b>: Nos mantenemos siempre a la vanguardia, actuando oportunamente ante las situaciones que se presentan.</li>
</ul>`,
            i: "valores",
          },
        ],
        en: [
          {
            t: "Policy ",
            c: "We are committed with every legal need of our clients, providing a personalized defense and consulting services.",
            i: "politicas",
          },
          {
            t: "Objective",
            c: "We provide integral legal services through the best specialists in each area of the firm, giving priority to the guiding principles of our work, which consist of an attitude of professionalism and commitment.",
            i: "objetivo",
          },
          {
            t: "Vision ",
            c: "To position the firm as the one that has the best customer service, distinguished by the ability to provide an integral legal service and an excellent command of each practice area.",
            i: "vision",
          },
          {
            t: "Mission",
            c: "To provide a professional service, prioritizing a close and personalized treatment, offering practical proposals for a good resolution of any legal conflict.",
            i: "mision",
          },
          {
            t: "Values",
            c: `<p>EIn Firma de Abogados Élite we are guided by professionalism, integrity, loyalty, commitment, and responsibility.</p>
<br />
<ul class="text-left">
<li><b>Professionalism</b>: We work with clarity and transparency following the highest standards.</li>
<li><b>Integrity</b>: Honesty and good faith prevail during conflict resolution of our clients’ matters.</li>
<li><b>Loyalty</b>: We provide security and confidence to our clients through a timely resolution of their matters.</li>
<li><b>Commitment</b>: We prioritize the interests of our clients and the adequate resolution of conflicts.</li>
<li><b>Responsibility</b>: We always keep ourselves at the forefront, acting in a timely manner in the situations that arise.</li>
</ul>`,
            i: "valores",
          },
        ],
      },
    };
  },
};
</script>
