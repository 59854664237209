<template>
  <div class="min-h-screen w-screen">
    <Menu :lang="lang" />

    <div class="mt-14">
      <div
        class="
          h-72
          bg-cover bg-center bg-no-repeat bg-dorado
          grid grid-cols-1
          place-content-end
        "
        id="bg"
      ></div>
      <p v-if="post.fecha">
        Publ. {{ moment(new Date(post.fecha), "MMM dd") }}
        {{ lang == "es" ? "por" : "by" }} {{ post.nombreAutor }}
        <a class="float-right text-elite" href="#/blog">
          <span v-if="lang == 'es'">Atrás</span>
          <span v-else>Back</span>
        </a>
      </p>
      <p>&nbsp;</p>
      <div class="container mx-auto p-4 text-justify">
        <h1 class="text-center text-2xl uppercase pb-8">
          {{ post.titulo }}
        </h1>
        <div v-if="contenido != ''" v-html="contenido"></div>
        <p>&nbsp;</p>
        <p class="text-right">
          <a
            href="#/blog"
            class="p-2 border-2 rounded-sm border-dorado text-sm text-dorado"
            >Volver</a
          >
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/components/Api";
import axios from "axios";
import Menu from "./Menu.vue";
import { format } from "date-fns";
export default {
  name: "areas",
  props: {
    id: {
      type: String,
      default: "0",
    },
    slug: {
      type: String,
      default: "",
    },
  },
  components: { Menu },
  data() {
    return {
      lang: Api.obtenerLocal("lang") || "es",
      cdn: Api.cdn + "/",
      url: Api.servidor,
      empresa: Api.obtenerLocal("bdlg.mx.empresa") || {},
      esMovil: Api.esMovil(),
      post: {
        copy: {
          es: {
            contenido: null,
          },
          en: {
            contenido: null,
          },
        },
        //imagen: 'http://placeimg.com/1000/1000/people/grayscale'
      },
    };
  },
  async mounted() {
    const id = this.id;
    const slug = this.slug;
    const url = this.url;
    console.log("Post", id);
    try {
      const post = (
        await axios.post(
          url + "/readbypass/postpublic",
          {},
          { headers: { "wst-appkey": "elite-abogados.mx" } }
        )
      ).data.filter((u) => (slug != "" ? slug == u.slug : u._id == id))[0];
      /*
      const autor = (
        await axios.post(url + "/readbypass/usuariopublic")
      ).data.filter((u) => u._id == post.idAutor)[0];
      post.nombreAutor = autor.nombre; //*/
      this.post = post;
      console.log("post?", id, post);
      //
      let bgeable = document.getElementById("bg");
      const fallback = "1x/Recurso 8-80.jpg";
      await Api.wait(1000);
      try {
        let f = this.post.imagen;
        if (f) {
          console.log("f", f);
          bgeable.style.backgroundImage = "url('" + this.cdn + f + "')";
        } else throw new Error("Img no encontrado: " + f);
      } catch (e) {
        console.error(e);
        bgeable.style.backgroundImage = "url('" + fallback + "')";
      } finally {
        console.log("U", bgeable.style, bgeable.style.backgroundImage);
      }
      /*bgeable.style.backgroundImage = "url('" + fallback + "')";
      console.log("u", bgeable.style.backgroundImage); //*/
    } catch (e) {
      console.error(e);
      //window.history.go(-1);
    }
  },
  computed: {
    contenido: function () {
      return ((this.post || {}).contenido || "").replace(/\\n/g, "<br />");
    },
  },
  methods: {
    moment(a, b) {
      return format(a, b);
    },
  },
};
</script>
